import { useState } from 'react';
import { getPdf } from './pdf/pdf';
import { getEmailContent } from './email/email.helper';
import { sendReceiptHttp } from './api/aws';
import './donation.css';

const SendButton = ({ state, onClick }) => {

  if (state === 'inProgress') {
    return 'Loading...'
  }

  let extraIcon = '';
  if (state === 'done') {
    extraIcon = <span className="doneIcon">✔</span>
  } else if (state === 'failed') {
    extraIcon = '❌'
  }

  return <>
    <span onClick={onClick}>Send</span>
    {extraIcon}
  </>
};


const Donation = (props) => {
  const {
    id,
    index,
    donatedAt,
    value,
    valueValid,
    // comments,
    firstName,
    firstNameValid,
    lastName,
    lastNameValid,
    email,
    emailValid,
    // notes,
    noSolicitations,
    receiptNumber,
    address,
    city,
    postalCode,
    subdivision,
    isItemValid,
    onDonationChange
  } = props;

  const [progressState, setProgressState] = useState();

  const donationDate = new Date(donatedAt);
  const addressValid = !!(address && city && postalCode && subdivision);
  const fullAddress = `${address || ''}, ${city || ''} ${postalCode || ''}, ${subdivision}`;

  const handleReceiptNumberChange = (e) => {
    onDonationChange({ index, partial: { receiptNumber: e.target.value } });
  }

  const handleView = () => {
    const addressParts = addressValid ? { streetLine: address, suburb: city, postalCode, city: subdivision } : null;
    const win = window.open('', '_blank');
    getPdf(firstName, lastName, email, donatedAt, value, receiptNumber, new Date(), addressParts).open({}, win);
  }

  const handleSend = () => {
    const pdfGenerator = getPdf(firstName, lastName, email, donatedAt, value, receiptNumber, new Date());
    setProgressState('inProgress');

    pdfGenerator.getBase64(async (pdfData) => {
      const pdfFileName = `${receiptNumber}.pdf`;
      const emailContent = getEmailContent(firstName);
      const [response, error] = await sendReceiptHttp(
        pdfData,
        pdfFileName,
        email,
        localStorage.getItem('EMAIL_BCC'),
        emailContent,
      );
      if (error) {
        console.log('Error sending email ', error);
        setProgressState('failed');
      } else {
        pdfGenerator.download(pdfFileName)
        console.log(response);
        setProgressState('done');
      }
    })
  }

  const handleDownload = () => {
    const addressParts = addressValid ? { streetLine: address, suburb: city, postalCode, city: subdivision } : null;
    const pdfGenerator = getPdf(firstName, lastName, email, donatedAt, value, receiptNumber, new Date(), addressParts);
    const pdfFileName = `${receiptNumber}.pdf`;
    pdfGenerator.download(pdfFileName);
  };

  return <tr className={`donationRow ${isItemValid ? '' : 'donationRowInvalid'}`}>
    <td><a target="_blank" href={`https://www.wrmd.org/people/${id}/edit`} rel="noreferrer">{id}</a></td>
    <td className={firstNameValid ? '' : 'invalidField'}>{firstName}</td>
    <td className={lastNameValid ? '' : 'invalidField'}>{lastName}</td>
    <td>{donationDate.toLocaleDateString('en-NZ')}</td>
    <td className={emailValid ? '' : 'invalidField'}>{email || <i>- missing email -</i>}</td>
    <td className={valueValid ? '' : 'invalidField'}>{value}</td>
    <td><input value={receiptNumber} onChange={handleReceiptNumberChange} /></td>
    <td className={!addressValid && !emailValid ? 'invalidField' : ''}>{fullAddress || <i>- missing address -</i>}</td>
    <td className={noSolicitations ? 'invalidField' : ''}>{noSolicitations ? '❌' : '✔'}</td>
    <td>
      <span className="textButton" onClick={handleView}>View</span>
      <span className="textButton" onClick={handleDownload}>Download</span>
      <span className="textButton">{isItemValid && <SendButton state={progressState} onClick={handleSend} />}</span>
    </td>
    {/* <td>{notes}</td>
    <td>{comments}</td> */}
  </tr>
};

export default Donation;
