import React, { useState } from 'react';
import DonationList from './donation.list';

const MainApp = () => {
  const [isTestMode, setIsTestMode] = useState(false);

  const handleTestModeChange = (e) => {
    setIsTestMode(e.target.checked);
  }

  return <div>
    <div style={{ position: 'fixed', top: 10, left: 10, backgroundColor: isTestMode ? 'rgba(255, 0, 0, 0.3)' : undefined }}>
      <div>
        <div style={{ fontWeight: 'bold' }}>TEST MODE:<input type="checkbox" onChange={handleTestModeChange} /> {isTestMode ? 'ON' : 'OFF'}</div>
        <div>Ignore the actual email address from the donation</div>
        <div>and send the receipt to yourself as a test.</div>
      </div>
    </div>
    <DonationList isTestMode={isTestMode} />
  </div>
};

export default React.memo(MainApp);