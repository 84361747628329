import pdfMake from 'pdfmake';
import { ruru, signature5, birdCareLogo } from './images';

pdfMake.fonts = {
  // download default Roboto font from cdnjs.com
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  },
}

export const getPdf = (firstName, lastName, email, donationDate, amount, receiptNumber, currentDate, addressData) => {
  const physicalAddress = addressData ? {
    text: `
      ${firstName} ${lastName}
      ${addressData.streetLine}
      ${addressData.suburb} ${addressData.postalCode}
      ${addressData.city}
    `,
    style: 'physicalAddress',
  } : {};

  const docDefinition = {
    content: [
      {
        image: birdCareLogo,
        width: 156,
        style: 'logo',
      },
      {
        text: currentDate.toLocaleDateString('en-NZ', { year: 'numeric', month: 'long', day: 'numeric' }),
        style: 'defaultText',
      },
      {
        text: `${firstName} ${lastName}`,
        style: 'defaultText',
      },
      {
        text: `
        Dear ${firstName},

        Thank you for your generous gift of $${amount} to BirdCare Aotearoa. We truly appreciate your kindness.
        `,
        style: 'defaultText',
      },
      {
        text: `Because of you, we are able to provide life-saving medical care and attention to injured and abandoned wild birds of New Zealand to give them a second chance at life.
        `,
        style: 'defaultText',
      },
      {
        columns: [
          {
            image: ruru,
            width: 320,
            style: 'ruruImage',
          },
          {
            text: `Image: Rehabilitation of a Ruru at BirdCare Aotearoa caught in a parapara tree.`,
            style: 'ruruText',
            margin: [0, 50, 0, 0],
          },
        ],
        style: 'ruruColumns',
      },
      {
        text: `You truly make a difference, ${firstName}, and we are extremely grateful!
        `,
        style: 'defaultText',
      },
      {
        text: `Thank you for your continued support.  We could not do it without you!
        `,
        style: 'defaultText',
      },
      {
        text: `With gratitude,`,
        style: 'defaultText',
      },
      {
        image: signature5,
        width: 50,
      },
      {
        text: `
        Kim Frakes
        Administration Manager, BirdCare Aotearoa
        74 Avonleigh Road, Green Bay, Auckland 0604
        _______________________________________________________________________________________________________________
        `,
        style: 'defaultText',
      },
      {
        text: 'BirdCare Aotearoa Donation Receipt',
        style: 'footerTitle',
      },
      {
        text: 'Charity Commission Number:  CC37288 / IRD Number: 099-610-379',
        style: 'charityDetails',
      },
      {
        style: 'footerColumns',
        columns: [
          {
            text: `
            Receipt:
            Date:
            Address:
            Donation:
            `,
            width: 80,
            style: 'defaultText',
          },
          {
            text: `
            ${receiptNumber} 
            ${(new Date(donationDate)).toLocaleDateString('en-NZ', { year: 'numeric', month: 'long', day: 'numeric' })} 
            ${email || ''} 
            $${amount}
            `,
            style: 'defaultText',
          },
        ]
      },
      physicalAddress,
    ],
    styles: {
      defaultText: { fontSize: 10 },
      logo: {
        alignment: 'right',
      },
      ruruImage: {
        alignment: 'center',
      },
      ruruColumns: {
        margin: [0, 0, 0, 10],
      },
      ruruText: {
        alignment: 'center',
        italics: true,
        fontSize: 9,
      },
      footerTitle: {
        fontSize: 10,
        bold: true,
        margin: [220, 0, 0, 0],
      },
      charityDetails: {
        fontSize: 8,
        margin: [220, 0, 0, 0],
      },
      footerColumns: {
        margin: [220, 0, 0, 0],
      },
      physicalAddress: {
        fontSize: 13,
        margin: [30, -15, 0, 0],
      },
    },
  };

  return pdfMake.createPdf(docDefinition);
};