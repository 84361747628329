import isEmailValid from '../validators/email';
const R = require('ramda');
const superagent = require('superagent');


const getDonationList = async (startDate, endDate) => {
  const BASE_URL = localStorage.getItem('WRMD_ENDPOINT');
  const API_KEY = localStorage.getItem('WRMD_ENDPOINT_KEY');

  let currentPage = 0;
  let lastPage = Number.MAX_SAFE_INTEGER;
  let data = [];
  let included = [];

  do {
    try {
      console.log(`Requesting page ${currentPage}`);
      const res = await superagent
        .get(`${BASE_URL}/donations`)
        .query({
          'page[number]': currentPage + 1,
          'page[size]': 50,
          'include': 'donor',
          'filter[startDate]': startDate,
          'filter[endDate]': endDate
        })
        .set('Authorization', `Bearer ${API_KEY}`)
        .set('Accept', 'application/vnd.api+json')
        .set('Content-Type', 'application/json');

      const response = JSON.parse(res.text);
      data = R.concat(data, response.data);
      included = R.concat(included, response.included)
      currentPage = R.path(['meta', 'page', 'currentPage'], response);
      lastPage = R.path(['meta', 'page', 'lastPage'], response);
    } catch (err) {
      throw new Error(';;; Error retrieving list of donations', `${BASE_URL}/donations`, err);
    }
  } while (currentPage < lastPage);

  return [data, included];
};

const getMultipleDonationsIndex = (donations) => donations.reduce((acc, item, index) => {
  const {
    id: donationId,
    attributes: { donatedAt },
    relationships: {
      donor: {
        data: { id: donorId }
      }
    }
  } = item;

  acc[donorId] = acc[donorId] || {};
  acc[donorId][donatedAt] = acc[donorId][donatedAt] || [];
  acc[donorId][donatedAt].push(donationId);
  return acc;
}, {});

const getReceiptNumber = (donationId, donorId, donatedAt, multipleDonationIndex) => {
  const donationDate = new Date(donatedAt);
  const year = donationDate.getFullYear().toString().substr(2);
  const month = String(donationDate.getMonth() + 1).padStart(2, '0');
  const day = String(donationDate.getDate()).padStart(2, '0');
  
  let suffix = '';
  const donationIdsPerUserPerDay = R.pathOr([], [donorId, donatedAt], multipleDonationIndex);
  if (donationIdsPerUserPerDay.length > 1) {
    const donationPosition = donationIdsPerUserPerDay.indexOf(donationId);
    if (donationPosition !== -1) {
      suffix = `-${donationPosition + 1}`
    }
  }
  return `BC-${donorId}-${year}${month}${day}${suffix}`;
};

export const getDonations = async (startDate, endDate) => {
  const [rawDonations, included] = await getDonationList(startDate, endDate);
  const includedIndexed = included.reduce((acc, { id, attributes }) => {
    return R.assoc(id, { ...attributes, id }, acc);
  }, {});

  const multipleDonationIndex = getMultipleDonationsIndex(rawDonations);


  return rawDonations.map(({ id: donationId, attributes: { donatedAt, value }, relationships: { donor: { data: { id: donorId } } } }) => {
    const donor = includedIndexed[donorId];
    const firstNameValid = !!donor.firstName;
    const lastNameValid = !!donor.lastName;
    const valueValid = !!value;
    const emailValid = !!isEmailValid(donor.email);
    const isItemValid = emailValid && firstNameValid && lastNameValid && valueValid && !donor.noSolicitations;
    const receiptNumber = getReceiptNumber(donationId, donorId, donatedAt, multipleDonationIndex);

    return {
      donationId,
      donatedAt,
      value,
      // donor id
      ...donor,
      emailValid,
      firstNameValid,
      lastNameValid,
      valueValid,
      isItemValid,
      receiptNumber,
    }
  });
};
