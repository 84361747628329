export const sendReceiptHttp = async (pdfData, pdfFileName, emailTo, emailBcc, emailContent) => {
  let content;
  let error;

  try {
    const rawResponse = await fetch(localStorage.getItem('AWS_FN_URL'), {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Api-Key-Auth': localStorage.getItem('AWS_FN_URL_API_KEY'),
      },
      body: JSON.stringify({
        pdfData,
        pdfFileName,
        emailContent,
        emailBcc,
        emailTo,
        emailFrom: 'BirdCare Admin <admin@birdcareaotearoa.org.nz>',
      })
    });
    content = await rawResponse.json();
  } catch (err) {
    error = err;
    alert('Error when trying to send email', err);
  }

  return [content, error];
};



