import React, { useState } from 'react';
import './settings.css';

const Settings = () => {

  const [wrmdEndpoint, setWrmdEndpoint] = useState(localStorage.getItem('WRMD_ENDPOINT'));
  const [wrmdEndpointKey, setWrmdEndpointKey] = useState(localStorage.getItem('WRMD_ENDPOINT_KEY'));
  const [emailBcc, setEmailBcc] = useState(localStorage.getItem('EMAIL_BCC'));
  const [awsFnUrl, setAwsFnUrl] = useState(localStorage.getItem('AWS_FN_URL'));
  const [awsFnUrlApiKey, setAwsFnUrlApiKey] = useState(localStorage.getItem('AWS_FN_URL_API_KEY'));

  const handleChange = (fn, localStorageKey) => (e) => {
    localStorage.setItem(localStorageKey, e.target.value);
    fn(e.target.value);
  };

  return (
    <div>
      <div>
        <div className="settingsInputContainer">
          <label className="settingsLabel" htmlFor="apiEndpointWRMD">WRMD:</label>
          <input className="settingsInput" name="apiEndpointWRMD" id="apiEndpointWRMD"
            value={wrmdEndpoint}
            onChange={handleChange(setWrmdEndpoint, 'WRMD_ENDPOINT')}
          />
        </div>
        <div className="settingsInputContainer">
          <label className="settingsLabel" htmlFor="apiEndpointWRMDKey">WRMD key:</label>
          <input className="settingsInput" name="apiEndpointWRMDKey" id="apiEndpointWRMDKey"
            value={wrmdEndpointKey}
            onChange={handleChange(setWrmdEndpointKey, 'WRMD_ENDPOINT_KEY')}
          />
        </div>
        <div className="settingsInputContainer">
          <label className="settingsLabel" htmlFor="emailBcc">Email to also receive receipt as BCC:</label>
          <input className="settingsInput" name="emailBcc" id="emailBcc"
            value={emailBcc}
            onChange={handleChange(setEmailBcc, 'EMAIL_BCC')}
          />
        </div>
        <div className="settingsInputContainer">
          <label className="settingsLabel" htmlFor="awsFnName">AWS Function URL:</label>
          <input className="settingsInput" name="awsFnName" id="awsFnName"
            value={awsFnUrl}
            onChange={handleChange(setAwsFnUrl, 'AWS_FN_URL')}
          />
        </div>
        <div className="settingsInputContainer">
          <label className="settingsLabel" htmlFor="awsFnName">AWS Function URL Api Key:</label>
          <input className="settingsInput" name="awsFnName" id="awsFnName"
            value={awsFnUrlApiKey}
            onChange={handleChange(setAwsFnUrlApiKey, 'AWS_FN_URL_API_KEY')}
          />
        </div>
      </div>
    </div>
  )

}

export default React.memo(Settings);