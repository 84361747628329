import { useCallback, useRef, useState } from 'react';
import { adjust, mergeLeft } from 'ramda';
import { getDonations } from './api/wrmd';
import Donation from './donation';
import './donation.list.css';

const DonationList = ({ isTestMode }) => {
  const refFrom = useRef('');
  const refTo = useRef('');
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [donations, setDonations] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const updateList = async () => {
    if (refFrom.current.value && refTo.current.value) {
      setInProgress(true);
      setDonations([]);
      const result = await getDonations(refFrom.current.value, refTo.current.value);
      setInProgress(false);
      setDonations(result);
    }
  };

  const handleDonationChange = useCallback(({ partial, index }) => {
    const updatedDonations = adjust(index, mergeLeft(partial), donations);
    setDonations(updatedDonations);
  }, [donations]);

  return <div style={{ border: isTestMode ? '3px solid rgba(255, 0, 0, 0.3)' : undefined }}>
    <label className="datePickerLabel" htmlFor="pickerFrom">From: </label>
    <input id="pickerFrom" className="datePicker" type="date" ref={refFrom} onChange={(e) => setDateFrom(e.target.value)} />
    <label className="datePickerLabel" htmlFor="pickerTo">To: </label>
    <input id="pickerTo" className="datePicker" type="date" ref={refTo} onChange={(e) => setDateTo(e.target.value)} />
    <button className="donationsRetrieveButton" onClick={updateList} disabled={!dateFrom || !dateTo}>GET LIST</button>
    {inProgress && <h3>Loading... </h3>}

    {donations.length > 0 &&
      <div className="donationTableContainer">
        <h3>Donations found: {donations.length}</h3>
        <table className="donationTable">
          <thead>
            <tr>
              <th>DonorID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Donated At</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Receipt No.</th>
              <th>Home Address</th>
              <th title="Donor is OK with solicitations">Solic.</th>
              <th>Actions</th>
              {/* <th>Donor Notes</th>
            <th>Donation Comments</th> */}
            </tr>
          </thead>
          <tbody>
            {donations.map((donation, index) => {
              return <Donation
                key={donation.donationId}
                {...donation}
                email={isTestMode ? localStorage.getItem('EMAIL_BCC') : donation.email}
                index={index}
                onDonationChange={handleDonationChange}
              />;
            })}
          </tbody>
        </table>
      </div>
    }
  </div>;
}

export default DonationList;
