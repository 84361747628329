import { useState } from 'react';
import MainApp from './main.app';
import Settings from './settings';
import './App.css';

const App = () => {
  const [panel, setPanel] = useState('app');

  return (
    <div className="App">
      <div className="header">
        <span
          className={`headerButton ${panel === 'app' && 'headerButtonSelected'}`}
          onClick={() => setPanel('app')}>
          APP
        </span>
        <span
          className={`headerButton ${panel === 'settings' && 'headerButtonSelected'}`}
          onClick={() => setPanel('settings')}>
          Settings
        </span>
      </div>
      {
        panel === 'app' ?
          <MainApp /> :
          <Settings />
      }
    </div>
  );
}

export default App;
